<template>
	<div>
		<div class="header"><appHeader></appHeader></div>
		<div style="height: 84px;"></div>
		<div class="row cc main-container">
			<div class="row page-main">
				<keep-alive><slot /></keep-alive>
			</div>
		</div>
		<appSideToolbar></appSideToolbar>
	</div>
</template>

<script>
import appHeader from './components/header.vue';

import appSideToolbar from './components/sideToolbar.vue';
export default {
	components: { appHeader, appSideToolbar },
	data() {
		return {};
	}
};
</script>
<style type="text/css">
body {
	background-color: #f6f6f6;
}
</style>
<style scoped lang="less">
.page-main {
	width: 1210px;
}
.header {
	position: fixed;
	top: 0;
	z-index: 101;
	width: 100%;
}
</style>
